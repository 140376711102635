<template>
  <div class="container" style="margin-top: 80px;min-height: 500px;">
    <div class="report-mes-content">
      <div class="font-title">企业年报</div>
      <div class="font-title" style="text-align: center;font-size: 24px;margin: 30px; 0">{{companyDetile.companyname}}{{companyDetile.year}}</div>
      <div class="dis-bet-cen title-two">
        <span>企业基本信息</span>
        <span>发布日期：2020-03-19</span>
      </div>
      <!-- 头部基本信息 -->
      <el-descriptions class="margin-top"  :column="2" :size="size" border>
        <el-descriptions-item :span="1">
          <template #label>统一社会信用代码/注册号</template>
          {{companyDetile.regno}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>企业经营状态</template>
          {{companyDetile.status}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>从业人数</template>
          {{companyDetile.employeecount}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>电子邮箱</template>
          {{companyDetile.emailaddress}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>本年度是否发生股东股权转让</template>
          {{companyDetile.isstockrighttransfer}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>企业是否有投资信息或购买其他公司股权</template>
          {{companyDetile.hasnewstockorbystock}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>企业联系电话</template>
          {{companyDetile.contactno}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>企业通信地址</template>
          {{companyDetile.address}}
        </el-descriptions-item>
      </el-descriptions>
      <!--股东及出资信息  -->
      <div class="dis-bet-cen title-two">
        <span>股东及出资信息</span>
      </div>
      <el-table
        border
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <span>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
      </el-table>
      <!--企业资产状况信息  -->
      <div class="dis-bet-cen title-two">
        <span>企业资产状况信息</span>
      </div>
      <el-descriptions class="margin-top"  :column="2" :size="size" border>
        <el-descriptions-item :span="1">
          <template #label>资产总额</template>
          {{companyDetile.totalassets || '-'}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>所有者权益合计</template>
          {{companyDetile.totalownersequity || '-'}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>销售总额</template>
          {{companyDetile.grosstradingincome || '-'}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>利润总额</template>
          {{companyDetile.totalprofit || '-'}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>营业总收入中主营业务收入</template>
          {{companyDetile.mainbusinessincome || '-'}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>净利润</template>
          {{companyDetile.netprofit || '-'}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>纳税总额</template>
          {{companyDetile.totaltaxamount || '-'}}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template #label>负债总额</template>
          {{companyDetile.totalliabilities || '-'}}
        </el-descriptions-item>
      </el-descriptions>
      <!--对外投资信息  -->
      <div class="dis-bet-cen title-two">
        <span>对外投资信息</span>
      </div>
      <el-table
        border
        :data="TableDataAnnual"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column v-for="(item,index) in tableHeaderTwo" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <span>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
      </el-table>
      <!--社保信息  -->
      <div class="dis-bet-cen title-two">
        <span>社保信息</span>
      </div>
      <table class="table-lsit" border="0" cellpadding='1'>
        <tr>
          <th>城镇职工基本养老保险</th>
          <th>{{companyDetileSb.ylIns || '-'}}</th>
          <th>职工基本医疗保险</th>
           <th>{{companyDetileSb.medicalInsurance || '-'}}</th>
        </tr>
        <tr>
          <th>生育保险</th>
          <th>{{companyDetileSb.syuIns || '-'}}</th>
          <th>失业保险</th>
           <th>{{companyDetileSb.syeIns || '-'}}</th>
        </tr>
        <tr>
          <th>工伤保险</th>
          <th colspan="3">{{companyDetileSb.gsIns || '-'}}</th>
        </tr>
        <tr>
          <th rowspan="5">单位缴费基数</th>
          <th colspan="2">单位参加城镇职工基本养老保险缴费基数</th>
          <th>{{companyDetileSb.companyYl || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">单位参加失业保险缴费基数</th>
          <th>{{companyDetileSb.companySye || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">单位参加职工基本医疗保险缴费基数</th>
          <th>{{companyDetileSb.companyMedical || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">单位参加工伤保险缴费基数</th>
          <th>{{companyDetileSb.companyGs || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">单位参加生育保险缴费基数</th>
          <th>{{companyDetileSb.companySyu || '-'}}</th>
        </tr>
        <tr>
          <th rowspan="5">本期实际缴费金额</th>
          <th colspan="2">参加城镇职工基本养老保险本期实际缴费金额</th>
          <th>{{companyDetileSb.actYl || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">参加失业保险本期实际缴费金额</th>
          <th>{{companyDetileSb.actSye || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">参加工伤保险本期实际缴费金额</th>
          <th>{{companyDetileSb.actGs || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">参加职工基本医疗保险本期实际缴费金额</th>
          <th>{{companyDetileSb.actMedical || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">参加生育保险本期实际缴费金额</th>
          <th>{{companyDetileSb.actSyu || '-'}}</th>
        </tr>
        <tr>
          <th rowspan="5">单位累计欠缴金额</th>
          <th colspan="2">单位参加城镇职工基本养老保险累计欠缴金额</th>
          <th>{{companyDetileSb.oweYl || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">单位参加失业保险累计欠缴金额</th>
          <th>{{companyDetileSb.oweSye || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">单位参加职工基本医疗保险累计欠缴金额</th>
          <th>{{companyDetileSb.oweMedical || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">单位参加工伤保险累计欠缴金额</th>
          <th>{{companyDetileSb.oweGs || '-'}}</th>
        </tr>
        <tr>
          <th colspan="2">单位参加生育保险累计欠缴金额</th>
          <th>{{companyDetileSb.oweSyu || '-'}}</th>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward'
export default {
  name: 'report-mes',
  data () {
    return {
      item: {},
      tableHeader: [{
        label: '股东',
        property: 'name'
      }, {
        label: '认缴出资额',
        property: 'shouldcapi'
      }, {
        label: '认缴出资日期',
        property: 'shoulddate'
      }, {
        label: '认缴出资方式',
        property: 'shouldtype'
      }, {
        label: '实缴出资额',
        property: 'realcapi'
      }, {
        label: '实缴出资日期',
        property: 'realdate'
      }, {
        label: '实缴出资方式',
        property: 'realtype'
      }],
      tableHeaderTwo: [{
        label: '注册号/统一社会信用代码',
        property: 'regNo'
      }, {
        label: '对外投资企业名称',
        property: 'name'
      }, {
        label: '持股比例',
        property: 'mortgageName'
      }, {
        label: '认缴出资额',
        property: 'regDate'
      }],
      TableData: [],
      TableDataAnnual: [],
      companyDetile: {},
      companyDetileSb: {},
      page: {
        pageNum: 1
      }
    }
  },
  mounted () {
    const vm = this
    const itemStr = sessionStorage.getItem('annual-report-count-item')
    if (itemStr) {
      const item = JSON.parse(itemStr)
      vm.item = item
      vm.init()
      vm.getAnnualReportPartner()
      vm.getAnnualInvestinfoList()
      vm.getAnnualSocialsecurityList()
    }
  },
  methods: {
    init () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyAnnualReport/getAnnualReportBasic',
        dateBackgroundRequestType: 'get',
        data: {
          pid: vm.item.id
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          console.log(res)
          vm.companyDetile = res
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getAnnualReportPartner () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyAnnualReport/getAnnualReportPartner',
        dateBackgroundRequestType: 'get',
        data: {
          pid: vm.item.id
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          console.log(res)
          const arr = []
          res.list.forEach(item => {
            item.realdate = item.realdate ? item.realdate.substring(0, 10) : '-'
            item.shoulddate = item.shoulddate ? item.shoulddate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.TableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getAnnualInvestinfoList () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyAnnualReport/getAnnualInvestinfoList',
        dateBackgroundRequestType: 'get',
        data: {
          pid: vm.item.id,
          pageNum: vm.page.pageNum,
          pageSize: 1000
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          console.log(res)
          const arr = []
          res.list.forEach(item => {
            item.realdate = item.realdate ? item.realdate.substring(0, 10) : '-'
            item.shoulddate = item.shoulddate ? item.shoulddate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.TableDataAnnual = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getAnnualSocialsecurityList () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyAnnualReport/getAnnualSocialsecurityList',
        dateBackgroundRequestType: 'get',
        data: {
          pid: vm.item.id,
          pageNum: vm.page.pageNum,
          pageSize: 1000
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.companyDetileSb = res.list[0] ? res.list[0] : {}
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.report-mes-content {
  background-color: #fff;
  padding: 20px;
}
.font-title {
  font-weight: bold;
  font-size: 20px;
  color: #333;
  text-align: left;
}
.title-two {
  font-size: 18px;
  color: #333;
  margin: 10px 0;
}
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
.table-border-bottom {
  width: 100%;
  min-width: 700px;
  border-bottom: 1px solid #EBEEF5;
  height: 40px;
}
.table-lsit {
  border-collapse:collapse;
  border:none;
  border:solid #EBEEF5 1px;
  width: 100%;
  color: rgb(102, 102, 102);
  font-size: 14px;
}
.table-lsit th{
  padding: 12px;
  border:solid #EBEEF5 1px;
}
</style>
